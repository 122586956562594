$primary: #002d73;
//$secondary: #ffaa00;
$info: #252525;
$warning: #a61d04;

@import "~bootstrap/scss/bootstrap";
html,body {
  height: 100%;
}
 
.mt-25 {
  margin-top: 25px !important;
}

.row {
  margin-right: 0px;
  margin-left: 0px;
}

.tooltip-inner {
  background-color: $primary;
}

.bs-tooltip-auto[x-placement^="bottom"] .arrow::before, .bs-tooltip-bottom .arrow::before{
	border-bottom-color: $primary;
}

.bs-tooltip-auto[x-placement^="top"] .arrow::before, .bs-tooltip-top .arrow::before{
	border-top-color:$primary;
}

.bs-tooltip-auto[x-placement^="left"] .arrow::before, .bs-tooltip-left .arrow::before{
	border-left-color: $primary;
}

.bs-tooltip-auto[x-placement^="right"] .arrow::before, .bs-tooltip-right .arrow::before{
	border-right-color: $primary;
}

.form-control {
  border: none;
  border-bottom: 2px solid #ced4da;
  border-radius: 0px;
  background: transparent;
  padding-left: 5px;
  box-shadow: none;
}

.form-control:focus {
  border-bottom-color: $primary;
  color: #000;
  box-shadow: none;
}

.card {
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
}

.hover:hover,
.hover:focus{
  transform: scale(1.02);
  .overlay-button{
    visibility: visible;
  }
}

.overlay-button{
  position: absolute;
  visibility: hidden;
}

.user-background {
  max-height: 200px;
  min-height: 200px;
}

.avatar {
  vertical-align: middle;
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.react-contextmenu {
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
  color: #373a3c;
  font-size: 16px;
  margin: 2px 0 0;
  min-width: 160px;
  outline: none;
  opacity: 0;
  padding: 5px 0;
  pointer-events: none;
  text-align: left;
  transition: opacity 250ms ease !important;
}

.react-contextmenu.react-contextmenu--visible {
  opacity: 1;
  pointer-events: auto;
  z-index: 9999;
}

.react-contextmenu-item {
  background: 0 0;
  border: 0;
  color: #373a3c;
  cursor: pointer;
  font-weight: 400;
  line-height: 1.5;
  padding: 3px 20px;
  text-align: inherit;
  white-space: nowrap;
}

.react-contextmenu-item.react-contextmenu-item--active,
.react-contextmenu-item.react-contextmenu-item--selected {
  color: #fff;
  background-color: #002d73;
  border-color: #002d73;
  text-decoration: none;
}

.react-contextmenu-item.react-contextmenu-item--disabled,
.react-contextmenu-item.react-contextmenu-item--disabled:hover {
  background-color: transparent;
  border-color: rgba(0, 0, 0, 0.15);
  color: #878a8c;
}

.react-contextmenu-item--divider {
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  cursor: inherit;
  margin-bottom: 3px;
  padding: 2px 0;
}
.react-contextmenu-item--divider:hover {
  background-color: transparent;
  border-color: rgba(0, 0, 0, 0.15);
}

.react-contextmenu-item.react-contextmenu-submenu {
  padding: 0;
}

.react-contextmenu-item.react-contextmenu-submenu
  > .react-contextmenu-item:after {
  content: '▶';
  display: inline-block;
  position: absolute;
  right: 7px;
}

.example-multiple-targets::after {
  content: attr(data-count);
  display: block;
}

.modal-90w {
  width: 90%;
  max-width: none!important;
}

@media (min-width: 1000px){
  .user-background {
    max-height: 300px;
    min-height: 300px;
  }
}

@media (min-width: 1500px){
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1450px;
  }
  .user-background {
    max-height: 400px;
    min-height: 400px;
  }
}

@media (min-width: 2000px){
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1950px;
  }
  .user-background {
    max-height: 400px;
    min-height: 400px;
  }
}

@media (min-width: 2500px){
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 2450px;
  }
  .user-background {
    max-height: 400px;
    min-height: 400px;
  }
}